<template>
  <portal to="body-footer">
    <div class="d-flex mt-2 pb-1 justify-content-between">
      <div
        class="d-flex"
        style="gap: 8px"
      >
        <b-button
          class="cancelBtn font-medium-1 font-weight-bolder"
          variant="outline-primary"
          @click="backToList"
        >
          {{ $t('Back to List') }}
        </b-button>
      </div>
      <div
        v-if="orderItem && btnProps.text"
        class="d-flex"
        style="gap: 8px"
      >
        <b-button
          v-if="Object.hasOwn(btnProps, 'isPrint') && btnProps.isPrint"
          variant="secondary"
          class="font-medium-1 font-weight-bold px-3"
          :disabled="isLoading"
          @click="printContent()"
        >
          <feather-icon icon="LPrintIcon" />
          {{ $t('Print Put Back List') }}
        </b-button>
        <b-overlay
          :show="isLoading"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="success"
        >
          <b-button
            variant="success"
            class="font-medium-1 font-weight-bold px-3"
            :disabled="isLoading || Object.hasOwn(btnProps, 'disabled') && btnProps.disabled"
            @click="submit"
          >
            <feather-icon :icon="btnProps.icon" />
            {{ btnProps.text }}
          </b-button>
        </b-overlay>
      </div>
    </div>

  </portal>
</template>
<script>
import { BButton, BOverlay } from 'bootstrap-vue'
import { printContent } from '@/views/main/orders/components/helpers'
import config from '@/views/main/warehouse/view/receive/config'
import mainOrderConfig from '@/views/main/warehouse/main-order/config'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'InBoundFormBottom',
  components: {
    BOverlay,
    BButton,
  },
  props: {
    btnProps: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      id: this.$route.params.id,
      orderType: this.$route.params.orderType,
    }
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
  },
  methods: {
    backToList() {
      this.$router.push({ name: 'home-warehouse-view' })
    },
    submit() {
      if (!this.btnProps.action) return

      this.isLoading = true

      const orderPropsByType = this.orderPropsByType(this.orderType)
      if (!orderPropsByType) return

      this.$store.dispatch(`${this.MODULE_NAME}/${this.btnProps.action}`, {
        body: { order_id: this.orderItem.id, total_order_notes: this.orderItem.internal_notes, warehouse_notes: this.orderItem.warehouse_notes },
        orderEndpoint: orderPropsByType.urlAction,
      }).then(data => {
        const { message } = data.data
        this.$toast({
          component: ToastificationContent,
          props: {
            icon: 'EditIcon',
            variant: 'success',
            text: message ?? 'Success',
          },
        })
        this.$store.dispatch(`${this.MODULE_NAME}/orderItem`, {
          id: this.id,
          orderEndpoint: orderPropsByType.urlAction,
        })
      }).catch(err => {
        this.errorNotification(this, err)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
  setup() {
    const {
      MODULE_NAME,
    } = config()
    const { orderPropsByType, ORDER_TYPES_KV } = mainOrderConfig()

    return {
      printContent,
      MODULE_NAME,
      orderPropsByType,
      ORDER_TYPES_KV,
    }
  },
}
</script>
