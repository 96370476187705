<template>
  <div>
    <div class="bg-white px-1 rounded-lg">
      <span class="font-weight-bolder font-medium-5 d-block pt-2">
        {{ $t('Order Information') }}
      </span>
      <b-row class="mt-1 pb-1">
        <b-col md="6">
          <b-row>
            <b-col mb="12">
              <b-col
                mb="6"
                class="d-flex align-items-center justify-content-between"
              >
                <span class="w-50 font-weight-bolder">
                  {{ $t('Customer') }}
                </span>
                <span class="w-50">
                  {{ getValueFromGivenObjectByKey(orderItem, 'customer.cust_name_dba', '&mdash;') }}
                </span>
              </b-col>
              <b-col
                mb="6"
                class="d-flex align-items-center justify-content-between mt-1"
              >
                <span class="w-50 font-weight-bolder">
                  {{ $t('Placed by') }}
                </span>
                <span class="w-50">
                  {{ getValueFromGivenObjectByKey(orderItem, 'return_contact.fullName', '&mdash;') }}
                </span>
              </b-col>
            </b-col>
            <!--            <b-col mb="4" />-->
          </b-row>
        </b-col>
        <b-col md="6">
          <!--          <b-row>-->
          <!--            <b-col mb="8">-->
          <!--              <b-col-->
          <!--                mb="8"-->
          <!--                class="d-flex align-items-center justify-content-between"-->
          <!--              >-->
          <!--                <span class="w-50 font-weight-bolder">-->
          <!--                  {{ $t('Agent') }}-->
          <!--                </span>-->
          <!--                <span class="w-50">-->
          <!--                  {{ getValueFromGivenObjectByKey(orderItem, 'agent', '&mdash;') }}-->
          <!--                </span>-->
          <!--              </b-col>-->
          <!--              <b-col-->
          <!--                mb="8"-->
          <!--                class="d-flex align-items-center justify-content-between mt-1"-->
          <!--              >-->
          <!--                <span class="w-50 font-weight-bolder">-->
          <!--                  {{ $t('PO/Event Ref.') }}-->
          <!--                </span>-->
          <!--                <span class="w-50">-->
          <!--                  {{ getValueFromGivenObjectByKey(orderItem, 'reference', '&mdash;') }}-->
          <!--                </span>-->
          <!--              </b-col>-->
          <!--            </b-col>-->
          <!--            <b-col mb="4" />-->
          <!--          </b-row>-->
        </b-col>
        <b-col
          v-for="field of ['internal_shipping_notes']"
          :key="field"
          md="6"
          class="mt-1"
        >
          <component
            :is="pickUpFields[field].type"
            v-model="orderItem[field]"
            style="width: 100%"
            v-bind="getProps(field, pickUpFields)"
          />
        </b-col>
        <b-col
          v-for="field of ['external_order_reference']"
          :key="field"
          md="6"
          class="mt-1"
        >
          <component
            :is="pickUpFields[field].type"
            :value="orderItem[field]"
            style="width: 100%"
            :is-editable="true"
            v-bind="getProps(field, pickUpFields)"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'
import { getProps, getValueFromGivenObjectByKey } from '@core/utils/utils'
import config from '../config'

export default {
  name: 'SubRentalOrderInformation',
  components: { BCol, BRow },
  props: {
    orderItem: {
      type: Object,
      required: true,
    },
  },
  methods: {
  },
  setup() {
    const { pickUpFields, MODULE_NAME } = config()
    return {
      getValueFromGivenObjectByKey,
      pickUpFields,
      MODULE_NAME,
      getProps,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
