<template>
  <b-row
    class="mb-1"
  >
    <b-col cols="6">
      <service-order-dispatch
        v-if="isServiceOrder"
        :order-item="orderItem"
      />
      <transfer-dispatch
        v-if="isTransferOrder"
        :order-item="orderItem"
      />
      <rental-sales-dispatch
        v-if="isRentalSalesOrder"
        :order-item="orderItem"
      />
      <sub-rental-dispatch
        v-if="orderType === ORDER_TYPES_KV.SR"
        :order-item="orderItem"
      />
    </b-col>
    <b-col cols="6">
      <service-order-return
        v-if="isServiceOrder"
        :order-item="orderItem"
      />
      <transfer-return
        v-if="isTransferOrder"
        :order-item="orderItem"
      />
      <rental-sales-return
        v-if="isRentalSalesOrder"
        :order-item="orderItem"
      />
      <sub-rental-return
        v-if="orderType === ORDER_TYPES_KV.SR"
        :order-item="orderItem"
      />
    </b-col>
  </b-row>
</template>
<script>
import config from '@/views/main/warehouse/view/receive/config'
import ServiceOrderDispatch from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderDispatch.vue'
import { BCol, BRow } from 'bootstrap-vue'
import ServiceOrderReturn from '@/views/main/warehouse/view/pick/service-order/components/ServiceOrderReturn.vue'
import mainOrderConfig from '@/views/main/warehouse/main-order/config'
import RentalSalesDispatch from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesDispatch.vue'
import RentalSalesReturn from '@/views/main/warehouse/view/pick/rental-sales/components/RentalSalesReturn.vue'
import TransferDispatch from '@/views/main/warehouse/view/pick/transfer/components/TransferDispatch.vue'
import TransferReturn from '@/views/main/warehouse/view/pick/transfer/components/TransferReturn.vue'
import SubRentalDispatch from '@/views/main/warehouse/view/pick/sub-rental/components/SubRentalDispatch.vue'
import SubRentalReturn from '@/views/main/warehouse/view/pick/sub-rental/components/SubRentalReturn.vue'

export default {
  name: 'InBoundFormBodyInfo',
  components: {
    SubRentalReturn,
    SubRentalDispatch,
    TransferReturn,
    TransferDispatch,
    RentalSalesReturn,
    RentalSalesDispatch,
    BCol,
    ServiceOrderReturn,
    BRow,
    ServiceOrderDispatch,
  },
  data() {
    return {
      orderType: this.$route.params.orderType.toUpperCase(),
    }
  },
  computed: {
    orderItem() {
      return this.$store.state[this.MODULE_NAME].orderItem
    },
    isServiceOrder() {
      return this.orderType === this.ORDER_TYPES_KV.SM
    },
    isTransferOrder() {
      return this.orderType === this.ORDER_TYPES_KV.TO
    },
    isRentalSalesOrder() {
      return this.orderType === this.ORDER_TYPES_KV.SO || this.orderType === this.ORDER_TYPES_KV.RO || this.orderType === this.ORDER_TYPES_KV.HO || this.orderType === this.ORDER_TYPES_KV.DS
    },
  },
  setup() {
    const { MODULE_NAME } = config()
    const { ORDER_TYPES_KV } = mainOrderConfig()

    return {
      MODULE_NAME,
      ORDER_TYPES_KV,
    }
  },
}
</script>
