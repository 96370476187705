import axios from '@/libs/axios'
import { customActionGet } from '@core/utils/utils'

const orderItem = (callBack, { id, orderEndpoint }) => customActionGet(`${orderEndpoint}/${id}/fulfillment`, null, callBack.commit, 'SET_ORDER_ITEM').catch(err => {
  this.errorNotification(this, err)
})

const receive = (callBack, { body, orderEndpoint }) => axios.post(`${orderEndpoint}/receive`, body)

const unpack = (callBack, { body, orderEndpoint }) => axios.post(`${orderEndpoint}/unpack`, body)

const putBack = (callBack, { body, orderEndpoint }) => axios.post(`${orderEndpoint}/put-back`, body)

const finishInbound = (callBack, { body, orderEndpoint }) => axios.post(`${orderEndpoint}/finish-inbound`, body)

export default {
  orderItem,
  receive,
  unpack,
  putBack,
  finishInbound,
}
