import { TEXTAREA_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const MODULE_NAME = 'sub-rental-pick'

  const PICKED_ACTION_STATE = 'Pick'
  const PACKED_ACTION_STATE = 'Pack'

  const tableColumns = [
    {
      key: 'action',
      label: '',
      thStyle: { width: '5%' },
    },
    {
      key: 'state',
      label: 'State',
      thStyle: { width: '5%' },
    },
    {
      key: 'product.sku',
      label: 'SKU',
      thStyle: { width: '10%' },
    },
    {
      key: 'product.name',
      label: 'Name',
      thStyle: { width: '30%' },
    },
    {
      key: 'location.level_name',
      label: 'Location',
      thStyle: { width: '15%' },
    },
    {
      key: 'asset_id',
      label: 'Asset ID',
      thStyle: { width: '12%' },
    },
    {
      key: 'pick_at',
      label: 'Picked',
      thStyle: { width: '12%' },
    },
    {
      key: 'inspected_outbound',
      label: 'Inspected',
      thStyle: { width: '25%' },
    },
  ]
  const quoteStatusBar = [
    {
      icon: 'LInOutIcon',
      title: 'Schedule',
      iconSize: '22',
      // stateID: 3,
    },
    {
      icon: 'LTruckIcon',
      title: 'Dispatch',
      iconSize: '22',
      // stateID: 4,
    },
    {
      icon: 'LWarehouseIcon',
      title: 'Receive',
      iconSize: '22',
      // stateID: 5,
    },
  ]
  const pickUpFields = {
    internal_shipping_notes: {
      type: TEXTAREA_INPUT,
      label: 'Warehouse Notes',
      placeholder: 'Write notes here...',
      options: {
        cols: '12',
      },
    },
    external_order_reference: {
      type: TEXTAREA_INPUT,
      label: 'Order Notes',
      placeholder: '',
      options: {
        cols: '12',
      },
    },
  }

  const getOrderItem = ctx => {
    ctx.$store.commit(`${MODULE_NAME}/SET_LOADING`, true)
    ctx.$store.dispatch(`${MODULE_NAME}/getOrders`, { order_id: ctx.$route.params.id }).then(res => {
      const { data } = res.data
      ctx.$store.commit(`${MODULE_NAME}/SET`, data)
    }).catch(err => {
      ctx.errorNotification(ctx, err)
    }).finally(() => {
      ctx.$store.commit(`${MODULE_NAME}/SET_LOADING`, false)
    })
  }

  return {
    MODULE_NAME,
    tableColumns,
    quoteStatusBar,
    PICKED_ACTION_STATE,
    PACKED_ACTION_STATE,
    pickUpFields,
    getOrderItem,
  }
}
